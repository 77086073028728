import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.59 57.61">
    <title>Loader </title>
    <g>
      <path
        d="M40.23,54.18c-.59.44-1.19.86-1.82,1.25a24.48,24.48,0,1,1-16.27-45"
        transform="translate(0 -2.5)"
        fill="none"
        stroke="#f2b2ac"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2px"
      />
      <g id="B" transform="translate(0 -2.5)">
        <path
          d="M26.87,5.49A.47.47,0,0,1,26.45,5a.46.46,0,0,1,.5-.41l.6,0L27.69,3a.55.55,0,1,1,1.09.1l-.14,1.61.79.07a.46.46,0,1,1-.09.92l-.78-.07-.38,4.17a.54.54,0,0,1-.59.5.55.55,0,0,1-.49-.6l.37-4.17Z
        M33.81,6.66c-1-.27-1.77.74-2.14,2.18l-.38,1.5a.54.54,0,0,1-.67.4.54.54,0,0,1-.39-.66L31.37,5.6A.55.55,0,0,1,32,5.19a.56.56,0,0,1,.4.67l-.19.75a1.59,1.59,0,0,1,1.85-1c.4.1,1,.35.79.9-.14.32-.46.31-.83.18Z
        M38.46,12.8a2.29,2.29,0,0,1-2.2,0,2.82,2.82,0,0,1-1.21-3.9,2.8,2.8,0,0,1,3.86-1.31,2.26,2.26,0,0,1,1.32,1.75L40.39,9a.54.54,0,1,1,1,.49l-2.09,4.12a.56.56,0,0,1-.75.24.56.56,0,0,1-.23-.74Zm0-4.23a1.78,1.78,0,0,0-2.46.81,1.84,1.84,0,1,0,2.46-.81Z
        M41.23,15.25a.55.55,0,1,1-.86-.67l4.7-6.05a.55.55,0,1,1,.87.67Z
        M43.2,17a.55.55,0,0,1-.79-.76l5.33-5.53a.54.54,0,1,1,.78.76Z
        M47.19,21.45a2.27,2.27,0,0,1-1.93-1A2.92,2.92,0,0,1,50,17a2.27,2.27,0,0,1,.35,2.17l.29-.2a.53.53,0,0,1,.76.12.55.55,0,0,1-.14.77l-3.77,2.66a.56.56,0,0,1-.77-.13.55.55,0,0,1,.14-.76Zm2-3.71a1.78,1.78,0,0,0-2.56-.44,1.85,1.85,0,1,0,2.56.44Z
        M52.92,24.68c-.41-1-1.65-1-3-.42l-1.43.59a.55.55,0,1,1-.41-1l4.27-1.75a.55.55,0,0,1,.72.29.56.56,0,0,1-.3.72l-.71.29a1.57,1.57,0,0,1,1.86.92c.16.38.33,1-.25,1.16-.34.08-.52-.18-.64-.55A1.69,1.69,0,0,0,52.92,24.68Z
        M57.94,30.89A.54.54,0,1,1,58.12,32l-7.56,1.27a.55.55,0,1,1-.18-1.08l.37-.06a2.26,2.26,0,0,1-1.29-1.77A2.81,2.81,0,0,1,51.88,27a2.77,2.77,0,0,1,3.34,2.31,2.26,2.26,0,0,1-.62,2.1Zm-3.76-1.27a1.76,1.76,0,0,0-2.11-1.49,1.84,1.84,0,1,0,2.11,1.49Z
        M51.33,35.38a.78.78,0,0,1-.79.77.76.76,0,0,1-.71-.82.75.75,0,0,1,.77-.75A.76.76,0,0,1,51.33,35.38Z
        M57,43.3a.56.56,0,0,1,.42.66.55.55,0,0,1-.65.41l-7.49-1.64a.55.55,0,0,1-.43-.65.56.56,0,0,1,.66-.42l.36.08a2.26,2.26,0,0,1-.54-2.12,2.81,2.81,0,0,1,3.47-2.15A2.77,2.77,0,0,1,55,40.86a2.22,2.22,0,0,1-1.36,1.72Zm-3-2.58a1.76,1.76,0,0,0-1.41-2.16A1.84,1.84,0,1,0,54,40.72Z
        M48.19,49.21l1.91-3.66a1.73,1.73,0,0,0-2.19.9A1.68,1.68,0,0,0,47.77,48a.56.56,0,0,1-.21.72.49.49,0,0,1-.65-.23A2.93,2.93,0,0,1,51,44.78a2.78,2.78,0,0,1,1.26,3.89,2.72,2.72,0,0,1-3.87,1.13A.44.44,0,0,1,48.19,49.21ZM49.24,49a1.64,1.64,0,0,0,2.12-.82,1.7,1.7,0,0,0-.51-2.27Z
        M43.9,51.46a.69.69,0,0,1,.05-.84l0,0a.77.77,0,0,1,.85-.19l4.63,1.52a.53.53,0,0,1,.29.71.54.54,0,0,1-.71.28l-4-1.42,2,3.79a.54.54,0,0,1-.16.74.53.53,0,0,1-.74-.19Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
